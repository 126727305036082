.search-container {
    width: 50vw;
    margin-left: 4%;
}

.searchbar {
    width: 90%;
    height: 52px;
    border-radius: 12px 0 0 12px;
    border: #ffffff;
    padding: 2%;
    font-size: 15px;
}

.searchicon {
    border-radius: 0 12px 12px 0;
}

#search::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #B6B6B6;
    font-size: 15px;
}

.big-top-panel > .d-flex:last-child {
    width: 100%;
    justify-content: center;
}

.big-top-panel > .d-flex:last-child > div {
    width: 100%;
    justify-content: center;
}

.home-product {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-right: 5%;
}

.home-product > .nav-link {
    color: #777777;
}

.home-product > .nav-link.active-category, .home-product > .nav-link:hover {
    color: var(--primary-color);
    font-weight: bold;
}

.home-product > .nav-link > hr {
    display: none;
}

.home-product > .nav-link.active-category > hr {
    display: block;
    width: 50%;
    height: 2px;
    color: var(--primary-color);
    opacity: 1;
    border-radius: 2px;
}

.left-link:active {
    color: #194389;
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
    .search-container {
        width: 5vw;
        margin-left: 0%;
    }
}