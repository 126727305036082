body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Poppins' sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    font-family: Poppins;
    --primary-color: #11448A;
    --secondary-color: #e1211d;
}

.para-text {
    font-family: Poppins;
    color: #777777;
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    margin-bottom: 3rem !important;
}

h1 {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: 0em;
}

h2 {
    font-family: Poppins;
    font-size: 26px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0em;
    color: #181818;
}

.container {
    max-width: 85%;
}

.nav-link {
    width: fit-content;
}

.btn {
    border-radius: 6px;
    border: 2px solid transparent;
}

.btn:focus {
    box-shadow: none;
}

.blue-button {
    align-items: center;
    background: var(--primary-color);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    border-color: transparent;
}

.blue-button:hover {
    background: white;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
}

.red-button {
    align-items: center;
    background: var(--secondary-color);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    border-color: transparent;
}

.red-button:hover {
    background: white;
    color: var(--secondary-color);
    border: 2px solid var(--secondary-color);
}

.white-button, .white-border-button:hover {
    align-items: center;
    background: #F9F9F9;
    font-style: normal;
    color: var(--primary-color);
    border-color: transparent;
}

.white-button:hover, .white-border-button {
    background: transparent;
    color: #F9F9F9;
    border: 2px solid #F9F9F9;
}

input {
    border-radius: 6px;
    border: #F1F1F1;
    box-shadow: none;
    background-color: #F9F9F9;
    padding: 3%;
}

input:focus-visible {
    outline: none;
}

input:focus, textarea:focus, textarea:focus-visible, .form-select:focus {
    box-shadow: none !important;
    outline: none !important;
}

.form-select:focus {
    border-color: #ced4da !important;
}

.flip-tag-icon {
    rotate: 90deg;
}

.white-button-blue-border {
    background-color: white;
    color: #11448A;
    border: 2px solid #11448A;
}

.white-button-blue-border:hover {
    background-color: #11448A;
    color: white;
    border: 2px solid #11448A;
}

.modal-header > .btn-close {
    margin-right: 0 !important;
}

@media (max-width: 992px) {
    .slick-slider {
        margin-bottom: 10%;
    }
}

@media (max-width: 768px) {
    .slick-slider {
        margin-bottom: 20%;
    }

    .modal-header > h1, .no-products {
        font-size: 28px !important;
    }
}

@media (max-width: 575px) {
    .modal-dialog {
        max-width: 100% !important;
    }
}

@media (max-width: 500px) {
    .term-and-condition-container > h1 {
        font-size: 24px;
        line-height: normal;
    }

    .term-and-condition-container > .para-text {
        font-size: 12px !important;
        line-height: 24px;
    }
}

@media (max-width: 400px) {
    .slick-slider {
        margin-bottom: 30%;
    }
}
@media(min-width:1200px){
    .term-op{
        font-size: 14px;
        line-height: 24px;
    }
}

