.category-selection {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    color: #777777;
}

.category-checkbox {
    border: 1px solid #B6B6B6;
    border-radius: 0px;
    cursor: pointer;
}

.category-checkbox:checked {
    background-color: black;
    border: 1px solid black;
    color: white;
}


.radio-black {
    width: 45px;
    height: 45px;
    cursor: pointer;
    background-color: #777777;
    border: 1px solid #E0E0E0;
}

.radio-black:hover {
    background-color: #000000;
}

.radio-white {
    width: 45px;
    height: 45px;
    cursor: pointer;
    background-color: lighwhite;
    border: 1px solid #E0E0E0;

}

.radio-white:hover {
    background-color: white;
}

.radio-blue {
    width: 45px;
    height: 45px;
    cursor: pointer;
    background-color: #CFD7FF;
    border: 1px solid #E0E0E0;

}

.radio-blue:hover {
    background-color: blue;
}

.radio-red {
    width: 45px;
    height: 45px;
    cursor: pointer;
    background-color: #FFDBDB;
    border: 1px solid #E0E0E0;
}

.radio-red:hover {
    background-color: red;
}


.size-circle-buttons > button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #B6B6B6;
    width: 45px;
    height: 45px;
    cursor: pointer;
    border: 1px solid #E0E0E0;
    border-radius: 50%;
    margin: 0 12px;
}

.product-dropdown-button {
    align-items: center;
    padding: 15px 28px;
    background: #FBFBFB;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    color: #B6B6B6;
}

.product-card > .card-body > .card-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #393939;
}

.product-card > .card-body > .card-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #B6B6B6;
}

.pagination > .page-item > .page-link {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #11448A;
    padding: 15px 25px;
    border: 2px solid #11448A;
    border-radius: 6px;
    margin: 0 5px;
}

.pagination > .page-item > .page-link:hover {
    background-color: #11448A;
    color: white;
}

.pagination > .pagination-dots {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #11448A;
}

.product-card > img {
    height: 25vh;
    object-fit: contain;
}

.pagination-bar {
    margin-top: 5%;
}

.desktop-filter-button {
    display: block;
}

.mobile-filter-button {
    display: none;
}

.size-circle-buttons > button {
    margin-bottom: 2%;
}

@media (max-width: 1300px) {
    .showing-results {
        width: 40%;
        height: auto !important;
    }

    .sort-by-dropdown, .filter-dropdown {
        width: 30%;
        height: auto !important;
    }
}

@media (max-width: 1100px) {
    .sort-product-container {
        display: block !important;
    }

    .sort-product-container > div, .sort-product-container > div > button {
        width: 100%;
    }

    .product-list-product {
        width: 50%;
    }
}

@media (max-width: 950px) {
    .product-list-container > .row > .col-12 {
        width: 50%;
    }

    .product-list-product {
        width: 100%;
        margin-bottom: 5%;
        text-align: center;
    }

    .product-card > .card-body > .card-title {
        text-align: center !important;
    }
}

@media (max-width: 750px) {
    .product-list-container > .row > .col-12 {
        width: 100%;
    }

    .product-list-container > .row > .col-12:first-child {
        display: none;
    }

    .sort-product-container {
        display: flex !important;
    }

    .showing-results {
        width: 40% !important;
    }

    .sort-by-dropdown, .filter-dropdown {
        width: 30% !important;
    }

    .desktop-filter-button {
        display: none;
    }

    .mobile-filter-button {
        display: block;
    }

    .sort-product-container > div > button, .showing-results > p {
        font-size: 14px;
        line-height: normal;
        margin-bottom: 0;
    }
}

@media (max-width: 650px) {
    .sort-product-container {
        display: block !important;
    }

    .sort-product-container > div, .sort-product-container > div > button {
        width: 100% !important;
    }

    .showing-results > p {
        text-align: center;
    }

    .sort-product-container > div > button {
        padding: 1%;
    }
}
