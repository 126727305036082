.quote-btn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    color: #F9F9F9;
    padding: 10px 25px;
    display: flex;
    text-align: center;
    align-items: center;
}

.request-quote > label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    color: #777777;
}

.request-quote > input, .form-select {
    background: #F9F9F9;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    color: #B6B6B6;
    border: none;
}

.form-select {
    height: 50%;
}

.request-quote > textarea {
    background: #F9F9F9;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    color: #B6B6B6;
    border: none;
}

.request-quote > textarea::placeholder {
    background: #F9F9F9;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #B6B6B6;
}

.request-quote > button {
    background: #F9F9F9;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #B6B6B6;
    text-align: left;
}


.country-dropdown-menu {
    background: #F9F9F9;
    border-radius: 6px;
    width: 97%;
}

.request-product-card > .card-body > .card-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    color: #5C5C5C;
}

.request-product-card > .card-body > .card-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    color: #181818;
}

.close-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-width: 0px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 0%;
    justify-content: center;
    align-items: center;
}

.deliveryandshippingcost-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #11448A;
}

.VAT-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #B6B6B6;
    padding-top:0;
}

.cost-value-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #181818;
}

.list-product-container {
    margin-top: 5%;
}
